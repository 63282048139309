@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Oswald', sans-serif;

    }
    h1 {
      font-family: 'Monomaniac One', sans-serif;
    }
}

